


























import { Component } from 'vue-property-decorator';
import NestedNavComponent from '../components/NestedNavComponent.vue';
import VendorHeadingComponent from '../components/VendorHeadingComponent.vue';
import BaseComponent from '../components/BaseComponent';
import { MenuMeta } from '../store/models';

@Component({
  components: {
    NestedNavComponent,
    VendorHeadingComponent,
  },
})
export default class PayeeSummaryNav extends BaseComponent {

  protected baseRoute: string = '/payees/id/';

  private refreshHeader: number = 0;

  private nestedMenu: MenuMeta[] = [
    {
      title: 'Payee Overview',
      href: '',
    },
    {
      title: 'Projects',
      href: '/projects',
    },
    {
      title: 'Expenses',
      href: '/expenses',
    },
  ];

  private payeeActions: any[] = [
    { label: 'Payee', code: 'payee' },
    { label: 'Payee Expense', code: 'payee-expense' },
  ];

  protected mounted() {
    this.baseRoute += this.$route.params.id;
  }

  private navigate(code) {
    switch (code) {
      case 'payee':
        this.changeRoute('/payees/new-payee', true);
        break;
      case 'payee-expense':
        this.changeRoute(this.baseRoute + '/new-expense', true);
        break;
    }
  }
}
